.footer-block {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  word-break: break-word;
  -webkit-box-flex: 1;
  padding: 0px;
  font-size: 0.85rem;
  font-weight: bold;
  padding-right: 16px;
  padding-left: 16px;
}

.compact {
  padding: 0;
}

.growable {
  flex: 1 0 auto;
}

.content-align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content-align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.empty-footer-block {
  visibility: hidden;
}

@media only screen and (max-width: 600px) {
  .empty-footer-block {
    display: none;
  }

  .footer-block {
    flex: 1 1 auto;
    padding-right: 0;
  }
}
