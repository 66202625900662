/*!
 * Bootstrap Steps v1.0.3 (https://github.com/ycs77/bootstrap-steps)
 * Copyright 2020 Lucas Yang <yangchenshin77@gmail.com>
 * Licensed under MIT (https://github.com/ycs77/bootstrap-steps/blob/master/LICENSE)
 */
.steps {
  padding: 0 0 20px 0;
  margin: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
  font-size: 12px;
}
.steps .step:first-child {
  margin-left: auto;
}
.steps .step:last-child {
  margin-right: auto;
}

.step:first-of-type .step-circle::before {
  display: none;
}
.step:last-of-type .step-content {
  padding-right: 0;
}

.step-content {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 7rem;
  min-width: 7rem;
  max-width: 7rem;
  padding-right: 0.5rem;
}

.step-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid #007cba;
  border-radius: 100%;
  background-color: #fff;
  color: #007cba;
}
.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: calc(7rem + 0.5rem - 3.1rem);
  height: 2px;
  transform: translate(-100%, -50%);
  color: #007cba;
  background-color: currentColor;
}

.step-text {
  color: #007cba;
  word-break: break-all;
  margin-top: 0.25em;
}

.step-active .step-circle {
  color: #fff;
  background-color: #2ed876;
  border-color: #2ed876;
}
.step-active .step-circle::before {
  color: #2ed876;
}
.step-active .step-text {
  color: #2ed876;
}

.step-error .step-circle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.step-error .step-circle::before {
  color: #dc3545;
}
.step-error .step-text {
  color: #dc3545;
}

.step-success .step-circle {
  color: #2ed876;
  background-color: #fff;
  border-color: #2ed876;
}
.step-success .step-circle::before {
  color: #2ed876;
}
.step-success .step-text {
  color: #2ed876;
}
