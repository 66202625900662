.pagination-actions-container {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 24px;
}

@media only screen and (max-width: 600px) {
  .pagination-actions-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }
}
