.table-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 48px;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  overflow: auto hidden;
  /* font-size: 13px;
  color: rgba(0, 0, 0, 0.54); */
}
