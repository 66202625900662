body {
  margin-bottom: 80px;
  background: #f3f9ff;
  line-height: 1.25;
}
a {
  color: #007cba;
}
a:hover {
  color: #2ed876;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: 300;
  color: #a7aeb5;
}
.h1,
h1 {
  font-size: 2.778em;
}
.h2,
h2 {
  font-size: 2.625em;
}

.content {
  padding-bottom: 30px;
}

.main-header {
  padding: 50px 0;
  margin-bottom: 20px;
  border-bottom: 0;
}

.main-footer {
  bottom: 0;
  position: fixed;
  width: 100%;

  background: #2a3d56;
  color: #fff;
  padding: 40px 0;
}

.card {
  box-shadow: none;
}
.card-title {
  font-size: 1.444em;
  font-weight: lighter;
  margin: 30px 0 30px 0;
  color: #6d6d6d;
}
.card-tools {
  margin: 30px 0 30px 0;
}
.card-success.card-outline {
  border-top: 3px solid #2ed876;
}
.card-footer {
  border-top: 1px solid #dee2e6;
  background-color: #ffffff;
}

.info-box {
  box-shadow: none;
}

.btn {
  border-radius: 20px;
}
.btn-tool {
  position: absolute;
  right: 0;
  margin: 0;
}
.btn-success {
  background-color: #2ed876;
  border-color: #2ed876;
}
.btn-success:hover,
.btn-success:visited,
.btn-success:active,
.btn-success:focus {
  background-color: #ffffff !important;
  border-color: #dee2e6 !important;
  color: #2ed876 !important;
}
a.btn-warning {
  color: #ffffff;
  padding: 6px 20px;
}
.btn-warning:hover,
.btn-warning:visited,
.btn-warning:active,
.btn-warning:focus {
  color: #ffffff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #303035;
  padding: 8px 7px;
}

.react-pdf__Document {
  background-color: #efefef;
}
.react-pdf__Page {
  text-align: center;
}
.react-pdf__Page__canvas {
  display: inline !important;
}
.react-pdf__Page__textContent {
  display: none;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: #2ed876 !important;
}

.sepa {
  height: 30px;
  width: 30px;
}

.mytips-logo {
  width: 134px;
}

.mobile-only {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .main-header {
    padding: 20px;
    margin-bottom: 5px;
    border-bottom: 0;
  }
  .main-footer {
    padding: 20px 0;
  }

  .mobile-only {
    display: inherit !important;
  }
}
